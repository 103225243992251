<template>
  <div class="pannel">
    <el-card>
      <div slot="header">
        <span>Search for</span>
      </div>
      <el-row type="flex" :gutter=5 class="condition_row"
      >
      <el-col
        ><el-input
          class="condition_input"
          placeholder="Enter a molecule name"
          suffix-icon="el-icon-tickets"
          v-model="condition.name"
          clearable
        >
        </el-input></el-col
      ><el-col
        ><el-input
          class="condition_input"
          placeholder="Enter a molecular formula"
          suffix-icon="el-icon-tickets"
          v-model="condition.molecular_formula"
          clearable
        >
        </el-input></el-col
      ><el-col
        ><el-input
          class="condition_input"
          placeholder="Enter a CAS number"
          suffix-icon="el-icon-tickets"
          v-model="condition.cas_number"
          clearable
        >
        </el-input></el-col
      ><el-col
        ><el-input
          class="condition_input"
          placeholder="Enter a CID number"
          suffix-icon="el-icon-tickets"
          v-model="condition.pub_chem_cid"
          clearable
        >
        </el-input></el-col
      ><el-col
        ><el-input
          class="condition_input"
          placeholder="Property or Activity"
          suffix-icon="el-icon-tickets"
          v-model="condition.content"
          clearable
        >
        </el-input></el-col
      ><el-col
        ><el-button type="primary" icon="el-icon-search" @click="handleSearch" round
          >Search</el-button
        ></el-col
      ></el-row
    >
    </el-card>
    <el-divider></el-divider>
    <el-table :data="infoList" style="width: 100%" v-loading="loading">
      <el-table-column prop="name" label="Name"> </el-table-column>
      <el-table-column prop="molecular_formula" label="Formula">
      </el-table-column>
      <el-table-column prop="cas_number" label="CAS">
        <template slot-scope="scope">
          {{
            scope.row.cas_number == "" ? "-" : scope.row.cas_number
          }}
        </template>
      </el-table-column>
      <el-table-column prop="pub_chem_cid" label="CID">
        <template slot-scope="scope">
          {{
            scope.row.pub_chem_cid == "" ? "-" : scope.row.pub_chem_cid
          }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="Time created" width="180">
        <template slot-scope="scope">
          <el-tag type="warning">{{
            timestampToTime(scope.row.create_time)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="Last updated" width="180">
        <template slot-scope="scope">
          <el-tag type="warning">{{
            timestampToTime(scope.row.update_time)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="user_name" label="Founder" width="80">
        <template slot-scope="scope">
          <el-tag type="danger" effect="plain">{{ scope.row.user_name }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Operatiion" width="100">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-view"
            @click="handleShowDetail(scope.$index, scope.row)"
            >View</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <MoleculeInfoShowDetail ref="MoleculeInfoShowDetail" />
  </div>
</template>

<script>
import MoleculeInfoShowDetail from '../MoleculeInfoShowDetail/index.vue'
export default {
  name: "MoleculeInfoSelect",
  components:{MoleculeInfoShowDetail},
  data() {
    return {
      loading:false,
      //分页数据--start
      pageSize: 10,
      pageNum: 1,
      total: 0,
      //分页数据--end
      //查询条件
      condition: {
        name: "",
        molecular_formula: "",
        cas_number: "",
        pub_chem_cid: "",
        content: "",
      },
      infoList: [],
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    //获取分子信息
    getInfo() {
      this.loading = true
      this.$store
        .dispatch("moleculeInfo/select", {
          page_size: this.pageSize,
          page_num: this.pageNum,
          name: this.condition.name,
          molecular_formula: this.condition.molecular_formula,
          cas_number: this.condition.cas_number,
          pub_chem_cid: this.condition.pub_chem_cid,
          content: this.condition.content,
        })
        .then((res) => {
          const { data } = res;
          this.total = data.total;
          this.infoList = data.info_list;
          this.loading = false
        }).catch(err =>{
          console.log(err);
          this.loading = true
        })
    },
    //分页控件函数--start
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getInfo();
    },
    //分页控件函数--end
    //根据条件模糊查询分子条目
    handleSearch(){
      this.getInfo()
    },
    //查看分子条目详情
    handleShowDetail(index, row) {
      this.$refs.MoleculeInfoShowDetail.handleOpen(row)
    },
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>

<style scoped>
.pannel {
  background-color: #fff;
}
::v-deep .el-card__header{
  padding: 10px ;
  color: #666;
}

.condition_title{
  text-align: center;
  line-height: 5vh;
}
</style>